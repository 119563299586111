const IFRAME_ID = 'tabby-checkout-frame';
const IFRAME_FILENAME = 'widgets-user-tracking.html';

export const getIframe = () =>
  new Promise<HTMLIFrameElement>((resolve) => {
    const element = document.getElementById(IFRAME_ID) as HTMLIFrameElement;

    if (element) {
      resolve(element);
    } else {
      const iframe = document.createElement('iframe');
      iframe.id = IFRAME_ID;
      iframe.src = `${
        process.env.CHECKOUT_HOST
      }/${IFRAME_FILENAME}?${new Date().getTime()}`;
      iframe.style.display = 'none';
      iframe.onload = () => resolve(iframe);
      document.body.append(iframe);
    }
  });
