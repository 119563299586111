export const createNormalizeChecking =
  <TResultType,>(
    transform: (value: any) => TResultType,
    validate: (value: TResultType) => boolean,
    descriptionError: string,
    defaultValue: TResultType
  ) =>
  (value: any) => {
    try {
      if (!value) return defaultValue;

      const result = transform(value);

      if (validate(result)) return result;

      throw new Error('Incorrect value');
    } catch {
      // eslint-disable-next-line no-console
      console.warn(descriptionError);
      return defaultValue;
    }
  };
