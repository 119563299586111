// eslint-disable-next-line @typescript-eslint/naming-convention
const TITLE_STYLES = (color: 'green' | 'blueviolet' | 'brown') =>
  [
    `color: ${color}`,
    'border-radius: 10px',
    'border: 1px solid',
    'padding: 2px 5px',
  ].join(';');

const NAME_STYLES = ['font-weight: bold'].join(';');

export const printColorfulLog = (
  title: string,
  name: string,
  obj: Record<string, any>,
  color: Parameters<typeof TITLE_STYLES>['0'] = 'green'
) =>
  // eslint-disable-next-line no-console
  console.log(
    `%c${title}%c : %c${name}%c`,
    TITLE_STYLES(color),
    {},
    NAME_STYLES,
    {},
    obj
  );
