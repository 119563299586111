import { useCallback, useState } from 'react';

const tuple = <T extends unknown[]>(...args: T): T => args;

export const useTogglerState = (initialState = false) => {
  const [state, setState] = useState(initialState);

  const switchOn = useCallback(() => setState(true), []);

  const switchOff = useCallback(() => setState(false), []);

  const toggle = useCallback(() => setState((prev) => !prev), []);

  return tuple(state, {
    switchOn,
    switchOff,
    toggle,
  });
};
