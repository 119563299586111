export function isReferringFiniteNumber(candidate) {
  switch (typeof candidate) {
    case 'number':
      return true;
    case 'string': {
      const priceNumber = candidate.replace(/,/g, '.').trim();
      return priceNumber !== '' && Number.isFinite(Number(priceNumber));
    }
    default:
      return false;
  }
}
