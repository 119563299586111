import { Currency } from 'src/types/currency';

export interface DataSetRecord {
  tabbyCurrency: Currency;
  tabbyPrice: number;
  tabbyInstallmentsCount: number;
  tabbyInfo?: 'payLater';
  tabbyLanguage?: string;
  tabbyLocale?: string;
}

export const getDataFromDataset = (dataset: DOMStringMap): DataSetRecord => {
  return Object.keys(dataset || {}).reduce((acc, key) => {
    return { ...acc, [key]: dataset[key] };
  }, {} as DataSetRecord);
};
