import { FunctionComponentElement } from 'react';
import ReactDOM from 'react-dom';

import { getElement } from './get-element';

export const renderToNodeDecorator = (
  className: string,
  component: FunctionComponentElement<any>
) => {
  let element = getElement(`.${className}`);

  if (element) {
    element.innerHTML = '';
  } else {
    element = document.createElement('div');
    element.className = className;
    document.body.appendChild(element);
  }

  ReactDOM.render(component, element);
};
