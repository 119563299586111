import { datadogRum, DefaultPrivacyLevel } from '@datadog/browser-rum';
import { DD_CLIENT_KEY, ENV, TAG } from 'src/constants/env';
import { Experiment } from 'src/types/experiment';

import { isIframeContext } from './is-iframe-context';

const COMMON_CONFIG = {
  applicationId: '78e12cd7-3ebe-4d60-be27-ae67a062391b',
  clientToken: DD_CLIENT_KEY ?? '',
  service: 'widget-integrations',
  site: 'datadoghq.eu',
  env: ENV,
  version: String(TAG).split('-')[0],
  defaultPrivacyLevel: 'mask-user-input' as DefaultPrivacyLevel,
  useCrossSiteSessionCookie: true,
  startSessionReplayRecordingManually: true,
};

const WEBVIEW_CONFIG = {
  sessionSampleRate: 5,
  sessionReplaySampleRate: 0,
};

const WIDGETS_CONFIG = {
  sessionSampleRate: 0.2,
  sessionReplaySampleRate: 0,
  trackUserInteractions: true,
  trackResources: true,
  allowedTracingOrigins: ['https://api.tabby.ai', /https:\/\/.*\.tabby\.dev/],
  enableExperimentalFeatures: ['feature_flags'],
};

const FULL_LOGGING_CONFIG = {
  sessionSampleRate: 100,
  sessionReplaySampleRate: 100,
};

const checkFullLogging = (experiments: Experiment[]) => {
  const fullLoggingExpValue = experiments.find(
    (exp) => exp.name === 'enableWidgetsFullLogging'
  )?.value as string;

  return Boolean(
    fullLoggingExpValue &&
      experiments.find((exp) => exp.name === fullLoggingExpValue)
  );
};

export function initializeDatadogRUMWidgets(experiments: Experiment[]) {
  const isDDInitialized = datadogRum.getInitConfiguration();

  if (isDDInitialized) return;

  datadogRum.addFeatureFlagEvaluation('version', String(TAG));

  experiments.forEach((experiment) => {
    datadogRum.addFeatureFlagEvaluation(experiment.name, experiment.value);
  });

  datadogRum.init({
    ...COMMON_CONFIG,
    ...WIDGETS_CONFIG,
    ...(checkFullLogging(experiments) ? FULL_LOGGING_CONFIG : {}),
    beforeSend: (event) => {
      // track resources related to tabby only
      if (
        event.type === 'resource' &&
        /tabby.(ai|dev)/.test(event.resource.url)
      ) {
        return true;
      }

      if (event.type === 'action') {
        return true;
      }

      // don't track any other events like errors/merchant resources/etc
      return false;
    },
  });
}

export function initializeDatadogRUMWebview(experiments: Experiment[]) {
  // start monitoring if we are in iframe context only
  if (!isIframeContext()) return;

  datadogRum.init({
    ...COMMON_CONFIG,
    ...WEBVIEW_CONFIG,
    ...(checkFullLogging(experiments) ? FULL_LOGGING_CONFIG : {}),
  });
}
