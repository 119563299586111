import { Experiment } from 'src/types/experiment';
import { axiosInstance } from 'src/utils/axios';

export const getExperiments = async (
  params: Record<string, string | number | undefined | null> = {}
): Promise<Experiment[]> => {
  try {
    const { data } = await axiosInstance.get<{ experiments: Experiment[] }>(
      '/experiments',
      { params }
    );

    return data.experiments || [];
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('Get experiments failed', e);

    return [];
  }
};
