import { Lang, Locale } from 'src/types/lang-locale-type';
import { getLangCode } from 'src/utils/get-lang-code';
import { getLocale } from 'src/utils/get-locale';

export const getLangAndLocale = (lang?: Lang, locale?: Locale) => {
  switch (true) {
    case !!lang && !locale:
      return { lang: lang as Lang, locale: getLocale(lang as Lang) };
    case !lang && !!locale:
      return { lang: getLangCode(locale as Lang), locale: locale as Locale };
    case !!lang && !!locale:
      return { lang: lang as Lang, locale: locale as Locale };
    default:
      return null;
  }
};
