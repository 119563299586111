import { FrameEventType } from '../events';
import { getIframe } from './get-iframe';

export const getPostMessenger = async () => {
  const iframe = await getIframe();

  return ({ type, payload }: { type: FrameEventType; payload?: any }) => {
    iframe.contentWindow?.postMessage(JSON.stringify({ type, payload }), '*');
  };
};
