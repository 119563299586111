import { useCallback } from 'react';
import { COUNTRY_NAME_BY_CURRENCY_MAP } from 'src/constants/country-name-by-currency-map';
import { INSTALLMENTS_COUNT_LIST_FOR_DEFAULT_PROMO } from 'src/constants/learn-more';
import {
  INSTALLMENTS_COUNT_DEFAULT,
  useAppContext,
} from 'src/context/store/app-provider';
import { AnalyticsType } from 'src/hooks/use-analytics';
import { useDocumentClick } from 'src/hooks/use-document-click';
import { useProduct } from 'src/hooks/use-product';
import { useTogglerState } from 'src/hooks/use-toggler-state';
import { InfoAttrName } from 'src/types/info-attr-name';
import { ProductType } from 'src/types/product-type';
import { SegmentEventType, TakeTypes } from 'src/types/segment';
import { ThemeType } from 'src/types/theme-type';
import { checkInstallmentsCount } from 'src/utils/check-installments-count';
import { getDataFromDataset } from 'src/utils/get-data-from-dataset';
import { getDeviceType } from 'src/utils/get-device-type';

export const usePromoSetup = (
  isAlshaya: boolean,
  onSegmentLog: AnalyticsType['onSegmentLog'],
  infoAttrName: InfoAttrName
) => {
  const {
    currency,
    installmentsCount,
    publicKey,
    merchantCode,
    getExperiment,
    dispatch,
  } = useAppContext<ThemeType>();
  const experiment2431 = getExperiment('CT-2431-pay-now') || 'control';

  const product = useProduct();

  // #region InstallmentsPopup
  const [
    isShowInstallmentsPopup,
    { switchOn: showInstallmentsPopup, switchOff: hideInstallmentsPopupToggle },
  ] = useTogglerState();

  const [
    conditionInstallmentsPopup,
    {
      switchOn: suspenseOnInstallmentsPopup,
      switchOff: suspenseOffInstallmentsPopup,
    },
  ] = useTogglerState();

  const hideInstallmentsPopup = (
    action: 'cross' | 'inactive zone' | 'continue'
  ) => {
    onSegmentLog?.({
      event: SegmentEventType.LEARN_MORE_POP_UP_CLOSED,
      publicKey: publicKey ? 'yes' : 'no',
      platformType: getDeviceType(),
      productType: 'installments',
      merchantIntegrationType: 'popupOnly',
      planSelected: installmentsCount,
      popupType: 'standardWithInfo',
      merchantCountry: COUNTRY_NAME_BY_CURRENCY_MAP[currency],
      action,
      merchantCode,
    });
    hideInstallmentsPopupToggle();
  };
  // #endregion

  // #region InstallmentsPopup
  const [
    isShowNonStandardInstallmentsPopup,
    {
      switchOn: showNonStandardInstallmentsPopup,
      switchOff: hideNonStandardInstallmentsPopupToggle,
    },
  ] = useTogglerState();

  const [
    conditionNonStandardInstallmentsPopup,
    {
      switchOn: suspenseOnNonStandardInstallmentsPopup,
      switchOff: suspenseOffNonStandardInstallmentsPopup,
    },
  ] = useTogglerState();

  const hideNonStandardInstallmentsPopup = (
    action: 'cross' | 'inactive zone' | 'continue'
  ) => {
    onSegmentLog?.({
      event: SegmentEventType.LEARN_MORE_POP_UP_CLOSED,
      publicKey: publicKey ? 'yes' : 'no',
      platformType: getDeviceType(),
      productType: 'installments',
      merchantIntegrationType: 'popupOnly',
      planSelected: installmentsCount,
      popupType: 'standardWithoutInfo',
      merchantCountry: COUNTRY_NAME_BY_CURRENCY_MAP[currency],
      action,
      merchantCode,
    });
    hideNonStandardInstallmentsPopupToggle();
  };
  // #endregion

  // #region PayLater
  const [
    isShowPopupPayLater,
    { switchOn: showPopupPayLater, switchOff: hidePopupPayLaterToggler },
  ] = useTogglerState();

  const [
    conditionPopupPayLater,
    { switchOn: suspenseOnPopupPayLater, switchOff: suspenseOffPopupPayLater },
  ] = useTogglerState();

  const hidePopupPayLater = (
    action: 'cross' | 'inactive zone' | 'continue'
  ) => {
    onSegmentLog?.({
      event: SegmentEventType.LEARN_MORE_POP_UP_CLOSED,
      publicKey: publicKey ? 'yes' : 'no',
      platformType: getDeviceType(),
      productType: 'payLater',
      merchantIntegrationType: 'popupOnly',
      planSelected: installmentsCount,
      popupType: 'standardWithInfo',
      merchantCountry: COUNTRY_NAME_BY_CURRENCY_MAP[currency],
      action,
      merchantCode,
    });
    hidePopupPayLaterToggler();
  };
  // #endregion

  const learnMoreClicked = useCallback(
    (type: TakeTypes<ProductType, 'installments' | 'payLater'>) => {
      const snippetType = Array<number | undefined>(3, 4).includes(
        installmentsCount
      )
        ? 'fullInformation'
        : 'notFullInformation';

      onSegmentLog?.({
        event: SegmentEventType.LEARN_MORE_CLICKED,
        publicKey: publicKey ? 'yes' : 'no',
        platformType: getDeviceType(),
        productType: type,
        merchantIntegrationType: 'popupOnly',
        planSelected: installmentsCount,
        snippetType: isAlshaya ? 'alshaya' : snippetType,
        merchantCountry: COUNTRY_NAME_BY_CURRENCY_MAP[currency],
        merchantCode,
      });
    },
    [
      currency,
      installmentsCount,
      isAlshaya,
      merchantCode,
      onSegmentLog,
      publicKey,
    ]
  );

  const openPopup = useCallback(
    (dataSet: DOMStringMap) => {
      const { tabbyInstallmentsCount: tabbyInstallmentsCountRaw } =
        getDataFromDataset(dataSet);

      const tabbyInstallmentsCount = checkInstallmentsCount(
        INSTALLMENTS_COUNT_DEFAULT
      )(tabbyInstallmentsCountRaw);

      const infoAttr = dataSet[infoAttrName];
      const [dataProductType] = infoAttr ? infoAttr.split(':') : ['', ''];

      switch (dataProductType) {
        case 'installments':
          switch (true) {
            case INSTALLMENTS_COUNT_LIST_FOR_DEFAULT_PROMO.includes(
              tabbyInstallmentsCount
            ) || experiment2431 !== 'control':
              showInstallmentsPopup();
              suspenseOnInstallmentsPopup();
              break;
            default:
              showNonStandardInstallmentsPopup();
              suspenseOnNonStandardInstallmentsPopup();
          }

          learnMoreClicked('installments');

          break;

        case 'payLater':
          showPopupPayLater();
          suspenseOnPopupPayLater();
          learnMoreClicked('payLater');

          break;
        default:
        // no default case
      }
    },
    [
      experiment2431,
      infoAttrName,
      learnMoreClicked,
      showInstallmentsPopup,
      showNonStandardInstallmentsPopup,
      showPopupPayLater,
      suspenseOnInstallmentsPopup,
      suspenseOnNonStandardInstallmentsPopup,
      suspenseOnPopupPayLater,
    ]
  );

  useDocumentClick(
    (e) => {
      const element = e.target as HTMLElement;
      const { dataset } = element;

      if (!dataset[infoAttrName]) return;

      e.preventDefault();

      dispatch({
        type: 'updateFromDataSet',
        payload: getDataFromDataset(dataset),
      });
      openPopup(dataset);
    },
    [infoAttrName, openPopup]
  );

  return {
    product,
    conditionInstallmentsPopup,
    isShowInstallmentsPopup,
    hideInstallmentsPopup,
    suspenseOffInstallmentsPopup,
    conditionPopupPayLater,
    isShowPopupPayLater,
    hidePopupPayLater,
    suspenseOffPopupPayLater,
    conditionNonStandardInstallmentsPopup,
    isShowNonStandardInstallmentsPopup,
    hideNonStandardInstallmentsPopup,
    suspenseOffNonStandardInstallmentsPopup,
  } as const;
};
