import { FunctionComponentElement } from 'react';
import ReactDOM from 'react-dom';
import { getElement } from 'src/utils/get-element';

export const renderToDecorator = (
  selector: string,
  component: FunctionComponentElement<any>
) => {
  const element = getElement(selector);

  if (!element) return;

  ReactDOM.render(component, element);
};
