import { INSTALLMENTS_COUNT_LIST_FOR_DEFAULT_PROMO } from 'src/constants/learn-more';
import { State } from 'src/context/store/store-reducer';
import { isReferringFiniteNumber } from 'src/utils/is-referring-finite-number';

export const getTypeOfContentPopup = ({
  priceRaw,
  currencyRaw,
  installmentsCount,
}: State) => {
  switch (true) {
    case INSTALLMENTS_COUNT_LIST_FOR_DEFAULT_PROMO.includes(
      installmentsCount
    ) &&
      !!priceRaw &&
      isReferringFiniteNumber(priceRaw):
      return 'schedule';
    case !!currencyRaw &&
      INSTALLMENTS_COUNT_LIST_FOR_DEFAULT_PROMO.includes(installmentsCount):
      return 'calculator';
    default:
      return 'default';
  }
};
