import { useEffect } from 'react';

export const useDocumentClick = (
  callback: (e: MouseEvent) => void,
  deps?: React.DependencyList
) => {
  useEffect(() => {
    const handler = (e: MouseEvent) => {
      if (!(e.target instanceof HTMLElement)) return;

      callback(e);
    };

    document.addEventListener('click', handler);

    return () => {
      document.removeEventListener('click', handler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
};
