import {
  WidgetDetailsRequestParams,
  WidgetOffers,
} from 'src/types/widget-offers';
import { axiosInstance } from 'src/utils/axios';

export const getWidgetOffers = async (
  params: WidgetDetailsRequestParams
): Promise<{
  aid: string;
  offers: WidgetOffers;
}> => {
  try {
    const { data } = await axiosInstance.get<{
      aid: string;
      offers: WidgetOffers;
    }>('/offers', {
      params,
    });

    return {
      aid: data.aid,
      offers: data.offers,
    };
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('Get widget offers failed', e);
    return { offers: { installments: [] }, aid: '' };
  }
};
