import { CardTheme } from 'src/types/card-theme';
import { ThemeType } from 'src/types/theme-type';
import { createNormalizeChecking } from 'src/utils/create-normalize-checking';

export const checkTheme = createNormalizeChecking(
  (v) => `${v}`.trim() as ThemeType,
  (v) => v in ThemeType || v in CardTheme,
  'The theme parameter has an incorrect value. Please check it',
  ThemeType.default
);
