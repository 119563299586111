import { dictionaryMapLanguage } from 'src/constants/dictionary-map-language';
import { Lang, Locale } from 'src/types/lang-locale-type';
import { getLangCode } from 'src/utils/get-lang-code';

export const checkLang = (
  value: Lang | undefined,
  {
    locale,
  }: {
    locale?: Locale;
  }
) => {
  try {
    return value ? getLangCode(value) : getLangCode(locale as Lang);
  } catch {
    // eslint-disable-next-line no-console
    console.warn('The lang parameter has an incorrect value. Please check it');
    return dictionaryMapLanguage.en.lang;
  }
};
