import { useEffect, useState } from 'react';
import { getWidgetOffers } from 'src/api/get-widget-offers';
import { AsyncRequestStatusType } from 'src/types/async-request-status';
import {
  WidgetDetailsRequestParams,
  WidgetOffers,
} from 'src/types/widget-offers';

export const useWidgetDetails = (
  {
    merchantCode,
    publicKey,
    currency,
    amount,
  }: Partial<WidgetDetailsRequestParams>,
  shouldRequestOffers: boolean
) => {
  const [widgetOffersData, setWidgetOffers] = useState<{
    offers: WidgetOffers;
    aid: string;
    status: AsyncRequestStatusType;
  }>({ offers: { installments: [] }, aid: '', status: 'idle' });

  useEffect(() => {
    let isMounted = true;

    if (
      shouldRequestOffers &&
      merchantCode &&
      publicKey &&
      currency &&
      amount
    ) {
      setWidgetOffers((prevState) => ({
        ...prevState,
        status: 'pending',
      }));

      (async () => {
        const data = await getWidgetOffers({
          merchantCode,
          publicKey,
          currency,
          amount,
        });

        if (isMounted) {
          setWidgetOffers({
            ...data,
            status: 'done',
          });
        }
      })();
    } else {
      setWidgetOffers((prevState) => ({
        ...prevState,
        status: 'done',
      }));
    }

    return () => {
      isMounted = false;
    };
  }, [shouldRequestOffers, merchantCode, publicKey, currency, amount]);

  return widgetOffersData;
};
