import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { getElement } from 'src/utils/get-element';

import { PortalProps } from './types';

export function Portal({ children, to }: PortalProps) {
  const [container, setContainer] = useState(() => getElement(to));

  useEffect(() => {
    const innerContainer = getElement(to);
    if (innerContainer) setContainer(innerContainer);
  }, [to]);

  return container && createPortal(children, container);
}
