import { Experiment, ExperimentRecord } from 'src/types/experiment';
import { ExperimentResult } from 'src/types/experiment-result';

export const findExperiment = <T extends keyof ExperimentRecord>(
  experimentName: T,
  experiments: Experiment[] = []
) =>
  experiments.find(({ name }) => name === experimentName)?.value as
    | ExperimentResult<T>
    | undefined;
