import { isAnyObject } from 'src/utils/is-any-object';

import { FrameEventType } from '../events';

export const listenMessage = <T>(responseType: FrameEventType) =>
  new Promise<T>((resolve) => {
    const handler = (event: MessageEvent<string>) => {
      try {
        if (isAnyObject(event.data)) return;

        const { type, payload } = JSON.parse(event.data);

        if (type !== responseType) return;

        resolve(payload);
        window.removeEventListener('message', handler);
      } catch (e) {
        // no action
      }
    };

    window.addEventListener('message', handler);
  });
