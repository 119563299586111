import { Lang } from 'src/types/lang-locale-type';

export const getLangCode = (lang: Lang = 'en'): Lang => {
  const [locale] = lang.replace(/-/g, '_').split('_');

  switch (locale.toLowerCase()) {
    case Lang.en:
    case 'eng':
      return Lang.en;
    case Lang.ar:
    case 'ara':
      return Lang.ar;
    default: {
      throw new Error(`Unexpected lang code: ${lang}`);
    }
  }
};
