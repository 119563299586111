export const useValuesToBoolean = <T extends Record<string, any>>(props: T) => {
  const resultProps = Object.entries(props).reduce(
    (acc, [keyName, value]) => ({
      ...acc,
      [keyName]: ['true', 'false'].includes(value) ? value === 'true' : value,
    }),
    props
  );

  return resultProps;
};
