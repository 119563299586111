import Cookies from 'js-cookie';
import { FrameEventType } from 'src/checkout-frame/events';
import { getCheckoutMessage } from 'src/checkout-frame/utils/get-checkout-message';
import { v4 as uuidv4 } from 'uuid';

const ENTITY_ID_KEY = 'xxx111otrckid';

export const getEntityId = async () => {
  /* запрашиваем через iFrame для того что бы id проставился в куку домена checkout.
   * это нужно для тех мерчей кто интегрируется без плагинов */
  const entityId = await getCheckoutMessage<string>({
    type: FrameEventType.getEntityId,
    payload: {
      /* забираем из домена мерча куку с id или генерируем новый id */
      entityId: Cookies.get(ENTITY_ID_KEY) || uuidv4(),
    },
  });
  /* утсановка в куку домена мерча для тех кто интегрируется через плагин */
  Cookies.set(ENTITY_ID_KEY, entityId);

  return entityId;
};
