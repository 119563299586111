import React, { ReactNode, Suspense, SuspenseProps } from 'react';

interface Props extends Partial<Pick<SuspenseProps, 'fallback'>> {
  condition: boolean;
  children: ReactNode;
}

export function ConditionSuspense({
  condition,
  fallback = null,
  children,
}: Props) {
  return <Suspense fallback={fallback}>{condition && children}</Suspense>;
}
