import { Analytics, AnalyticsBrowser } from '@segment/analytics-next';
import { getEntityId } from 'src/checkout-frame/api/get-entity-id';

export interface AnalyticsPlatform {
  initialize: () => void;
  logEvent: (eventName: string, eventParams?: Record<string, any>) => void;
}

export class Segment implements AnalyticsPlatform {
  private params: Record<string, any>;

  private analytics?: Analytics;

  constructor(params: Record<string, any> = {}) {
    this.params = params;
  }

  public logEvent(eventName: string, eventParams?: Record<string, any>) {
    const context = {
      ...this.params,
      ...eventParams,
    };

    this.analytics?.track(eventName, context);
  }

  public async initialize() {
    const [analytics] = await AnalyticsBrowser.load(
      {
        writeKey: process.env.SEGMENT_KEY ?? '',
      },
      {
        integrations: {
          'Segment.io': {
            apiHost: process.env.SEGMENT_HOST ?? '',
          },
        },
        retryQueue: false,
      }
    );

    const entityId = await getEntityId();

    analytics.setAnonymousId(entityId);

    this.analytics = analytics;
  }
}
