import { FunctionComponentElement } from 'react';
import { renderToDecorator } from 'src/utils/render-to-decorator';
import { renderToNodeDecorator } from 'src/utils/render-to-node-decorator';

const CLASS_NAME = 'tabby-promo';

export const renderToByDefaultDecorator = (
  selector: string,
  component: FunctionComponentElement<any>
) => {
  if (selector) renderToDecorator(selector, component);
  else renderToNodeDecorator(CLASS_NAME, component);
};
