import { Lang, Locale } from 'src/types/lang-locale-type';

export const defaultLocale = {
  [Lang.en]: Locale['en-US'],
  [Lang.ar]: Locale['ar-AE'],
};

export const getLocale = (lang: Lang, locale?: Locale): Locale => {
  const isLocale = locale && locale in Locale;
  const localeCode = isLocale ? locale : defaultLocale[lang];

  return localeCode as Locale;
};
