import { useEffect } from 'react';
import { useAppContext } from 'src/context/store/app-provider';
import { Experiment } from 'src/types/experiment';

export const useOnExperimentsReady = (
  fn: (experiments: Experiment[]) => void
) => {
  const { experiments, experimentsStatus } = useAppContext();

  useEffect(() => {
    if (experimentsStatus === 'done') {
      fn(experiments);
    }
  }, [experiments, experimentsStatus, fn]);
};
