import React, { ReactNode, useCallback, useMemo } from 'react';
import { useAppContext } from 'src/context/store/app-provider';
import { useAnalytics } from 'src/hooks/use-analytics';
import { ProductType } from 'src/types/product-type';
import { SegmentEventType } from 'src/types/segment';

export interface AnalyticsContextProps {
  onSegmentLog: ReturnType<typeof useAnalytics>['onSegmentLog'];
  sendLearnMoreOpenEvent: (merchantId?: string) => void;
}

const defaultValueAnalytics: AnalyticsContextProps =
  {} as AnalyticsContextProps;

const AnalyticsContext = React.createContext<AnalyticsContextProps>(
  defaultValueAnalytics
);

AnalyticsContext.displayName = 'AnalyticsContext';

export const useAnalyticsContext = () => React.useContext(AnalyticsContext);

export interface AnalyticsProviderProps {
  children?: ReactNode;
  props?: Record<string, any>;
}

export function AnalyticsProvider({ children, props }: AnalyticsProviderProps) {
  const { merchant, experiments } = useAppContext();

  const analyticsData = useMemo(
    () => ({ ...props, merchant }),
    [merchant, props]
  );

  const { onSegmentLog } = useAnalytics(analyticsData);

  const sendLearnMoreOpenEvent = useCallback(
    (merchantId?: string) => {
      try {
        onSegmentLog?.({
          event: SegmentEventType.LEARN_MORE_OPENED,
          productType: ProductType.installments,
          merchantId,
          experiments,
        });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    },
    [onSegmentLog, experiments]
  );

  const value = useMemo(
    () => ({ onSegmentLog, sendLearnMoreOpenEvent }),
    [onSegmentLog, sendLearnMoreOpenEvent]
  );

  return (
    <AnalyticsContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={value}
    >
      {children}
    </AnalyticsContext.Provider>
  );
}
