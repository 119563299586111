import isMobile from 'ismobilejs/dist/isMobile.min';
import { getIsMobileWebview } from 'src/utils/get-is-mobile-webview';

export const getDeviceType = () => {
  switch (true) {
    case getIsMobileWebview():
      return 'merchant app';
    case isMobile.phone:
      return 'merchant web mobile';
    default:
      return 'merchant web desktop';
  }
};
