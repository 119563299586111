import { CountryName } from 'src/types/country-name';
import { Experiment } from 'src/types/experiment';
import { ProductType } from 'src/types/product-type';
import { getDeviceType } from 'src/utils/get-device-type';

export const SegmentEventType = {
  LEARN_MORE_OPENED: 'Learn More Opened',
  SNIPPET_CART_RENDERED: 'Snippet Cart Rendered',
  LEARN_MORE_CLICKED: 'Learn More Clicked',
  LEARN_MORE_POP_UP_OPENED: 'Learn More Pop Up Opened',
  LEARN_MORE_POP_UP_CLOSED: 'Learn More Pop Up Closed',
  PAY_LATER_LEARN_MORE_RENDERED: 'Pay Later Learn More Rendered',
} as const;

export type SegmentEventType =
  typeof SegmentEventType[keyof typeof SegmentEventType];

export type TakeTypes<T, R extends T> = R;

export type SnippetType = 'fullInformation' | 'notFullInformation' | 'alshaya';

export type SegmentEvent =
  | {
      event: typeof SegmentEventType.LEARN_MORE_OPENED;
      productType: ProductType;
      experimentName?: string;
      experimentValue?: string;
      experiments?: Experiment[];
      merchantId?: string;
    }
  | {
      event: typeof SegmentEventType.SNIPPET_CART_RENDERED;
      publicKey: 'yes' | 'no';
      platformType: ReturnType<typeof getDeviceType>;
      productType?: TakeTypes<ProductType, 'installments' | 'payLater'>;
      planSelected?: number;
      snippetType: SnippetType;
      merchantCountry: CountryName;
      merchantCode?: string;
      fingerprint?: string;
      sourcePlugin: 'woo' | null;
      merchantId: string;
    }
  | {
      event: typeof SegmentEventType.LEARN_MORE_CLICKED;
      publicKey: 'yes' | 'no';
      platformType: ReturnType<typeof getDeviceType>;
      productType?: TakeTypes<ProductType, 'installments' | 'payLater'>;
      merchantIntegrationType: 'popupOnly' | 'snippetAndPopup';
      planSelected?: number;
      snippetType: SnippetType;
      merchantCountry: CountryName;
      merchantCode?: string;
    }
  | {
      event: typeof SegmentEventType.LEARN_MORE_POP_UP_OPENED;
      publicKey: 'yes' | 'no';
      platformType: ReturnType<typeof getDeviceType>;
      productType?: TakeTypes<ProductType, 'installments' | 'payLater'>;
      merchantIntegrationType: 'popupOnly' | 'snippetAndPopup';
      planSelected?: number;
      popupType:
        | 'standardWithInfo'
        | 'standardPickPayment'
        | 'standardWithoutInfo'
        | 'custom';
      merchantCountry: CountryName;
      merchantCode?: string;
    }
  | {
      event: typeof SegmentEventType.LEARN_MORE_POP_UP_CLOSED;
      publicKey: 'yes' | 'no';
      platformType: ReturnType<typeof getDeviceType>;
      productType?: TakeTypes<ProductType, 'installments' | 'payLater'>;
      merchantIntegrationType: 'popupOnly' | 'snippetAndPopup';
      planSelected?: number;
      popupType:
        | 'standardWithInfo'
        | 'standardPickPayment'
        | 'standardWithoutInfo'
        | 'custom';
      merchantCountry: CountryName;
      action: 'cross' | 'inactive zone' | 'continue';
      merchantCode?: string;
    }
  | {
      event: typeof SegmentEventType.PAY_LATER_LEARN_MORE_RENDERED;
      publicKey?: string;
      merchantCode?: string;
      merchantCountry: CountryName;
    };
