import { datadogRum } from '@datadog/browser-rum';
import { useCallback, useMemo } from 'react';
import { SegmentEvent } from 'src/types/segment';
import { printColorfulLog } from 'src/utils/print-colorful-log';
import { AnalyticsPlatform, Segment } from 'src/utils/segment';

export interface AnalyticsType {
  onReady: (onAction: (analytics: AnalyticsPlatform) => void) => Promise<void>;
  onSegmentLog: (data: SegmentEvent) => void;
}

const isProduction = process.env.NODE_ENV === 'production';

export const useAnalytics = (props?: Record<string, any>): AnalyticsType => {
  const analyticsPromise = useMemo(async () => {
    try {
      const segment = new Segment(props);

      await segment.initialize();

      return segment;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Segment.io is not available', e);

      return null;
    }
  }, [props]);

  const onReady = useCallback(
    async (onAction: (analytics: AnalyticsPlatform) => void) => {
      const analytics = await analyticsPromise;

      if (analytics) onAction(analytics);
    },
    [analyticsPromise]
  );

  const onSegmentLog = useCallback<AnalyticsType['onSegmentLog']>(
    ({ event, ...data }) => {
      if (!isProduction) {
        printColorfulLog('Segment', event, { ...props, ...data });
      }

      onReady((analytics) => {
        analytics.logEvent(event, {
          ...data,
        });

        datadogRum.addAction(event, data); // logs all analytics events to DD
      });
    },
    [onReady, props]
  );

  return {
    onReady,
    onSegmentLog,
  };
};
