import { useAppContext } from 'src/context/store/app-provider';
import { ProductType } from 'src/types/product-type';
import { ThemeType } from 'src/types/theme-type';

const allowedThemes = ['ikea', 'levelshoes'];

const getThemeByPublicKey = (key: string) =>
  ((
    {
      'pk_eb817d07-1248-4645-92b2-4a98ba5e52f8': 'levelshoes',
    } as Record<string, ThemeType>
  )[key]);

const useGetTheme = () => {
  const { publicKey, theme } = useAppContext();
  const currentTheme =
    (publicKey && getThemeByPublicKey(publicKey)) || theme || 'default';

  return allowedThemes.includes(currentTheme) ? currentTheme : 'default';
};

export const useProduct = () => {
  const { productType } = useAppContext<ThemeType>();
  const currentTheme = useGetTheme();

  if (currentTheme === ProductType.ikea || productType === ProductType.ikea)
    return ProductType.ikea;

  if (
    currentTheme === ProductType.levelshoes ||
    productType === ProductType.levelshoes
  )
    return ProductType.levelshoes;

  return ProductType.default;
};
