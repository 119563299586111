import { dictionaryMapLanguage } from 'src/constants/dictionary-map-language';
import { Lang, Locale } from 'src/types/lang-locale-type';
import { getLangCode } from 'src/utils/get-lang-code';
import { getLocale } from 'src/utils/get-locale';

export const checkLocale = (
  value: Locale | undefined,
  { lang }: { lang?: Lang }
) => {
  try {
    return lang
      ? getLocale(getLangCode(lang), value)
      : getLocale(getLangCode(value as Lang));
  } catch {
    // eslint-disable-next-line no-console
    console.warn(
      'The locale parameter has an incorrect value. Please check it'
    );
    return dictionaryMapLanguage.en.locale;
  }
};
