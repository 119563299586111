import { useLocale } from 'src/hooks/use-locale';

export const LangDictionary = {
  en: 'en',
  ar: 'ar',
  'ar-QA': 'ar-QA',
} as const;

export type LangDictionary = typeof LangDictionary[keyof typeof LangDictionary];

export const Lang = {
  en: 'en',
  ar: 'ar',
} as const;

export type Lang = keyof typeof Lang;

export const Locale = {
  'en-US': 'en-US',
  'ar-AE': 'ar-AE',
  'ar-SA': 'ar-SA',
  'ar-KW': 'ar-KW',
  'ar-BH': 'ar-BH',
  'ar-QA': 'ar-QA',
} as const;

export type Locale = typeof Locale[keyof typeof Locale];

export type Translation = ReturnType<typeof useLocale>;
