import { FrameEventType } from '../events';
import { getPostMessenger } from './get-post-messenger';
import { listenMessage } from './listen-message';

export const getCheckoutMessage = async <T>({
  type,
  payload,
}: {
  type: FrameEventType;
  payload?: any;
}) => {
  const postMessage = await getPostMessenger();

  postMessage({
    type,
    payload,
  });

  return listenMessage<T>(type);
};
