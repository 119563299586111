import isWebview from 'is-ua-webview';

export const getIsMobileWebview = () =>
  Boolean(
    isWebview(window.navigator?.userAgent) ||
      window?.webkit?.messageHandlers?.ReactNativeWebView ||
      window.ReactNativeWebView ||
      window.tabbyMobileSDK ||
      window.tabbyAppListener
  );
