import { isReferringFiniteNumber } from 'src/utils/is-referring-finite-number';

export const useValuesToNumber = <T extends Record<string, any>>(props: T) => {
  const resultProps = Object.entries(props).reduce(
    (acc, [keyName, value]) => ({
      ...acc,
      [keyName]: isReferringFiniteNumber(value) ? Number(value) : value,
    }),
    props
  );

  return resultProps;
};
